import { Control, Player } from '@yleisradio/areena-types';
import { useEffect, useState } from 'react';
import { useReference } from 'hooks/useReference';
import { getAvailablePlayerByPointer } from 'services/areena-api/fetchers';
import logger from 'services/logger';
import {
  isNavigator,
  isReference,
} from 'components/Controls/Control/typeGuards';
import { useLocationParameters } from 'hooks/useLocationParameters';

export function usePrimaryPlayer(
  primaryPlayControl: Control | undefined
): Player | null {
  const [primaryPlayer, setPrimaryPlayer] = useState<Player | null>(null);
  const reference = isReference(primaryPlayControl) ? primaryPlayControl : null;
  const finalPlayControl =
    useReference(reference).control || primaryPlayControl;

  const locationParameters = useLocationParameters();

  useEffect(() => {
    (async () => {
      if (isNavigator(finalPlayControl)) {
        try {
          if (!locationParameters) {
            throw new Error('Location parameters are not available');
          }

          const player = await getAvailablePlayerByPointer(
            finalPlayControl.destination,
            locationParameters
          );

          if (player) {
            setPrimaryPlayer(player);
          }
        } catch (e) {
          logger.warn(e, 'Error occurred while fetching primary player');
        }
      }
    })();
  }, [finalPlayControl, locationParameters, setPrimaryPlayer]);

  return primaryPlayer;
}
